// Importing required libraries and styles for the Announcement Form component
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import './AnnouncementForm.css';

// List of stock ticker symbols for the dropdown menu
const symbols = [
  // Add your stock symbols here (e.g., "AAPL", "MSFT", "TSLA")
];

// Generate a list of strike prices from 0 to 3000 for dropdown input
const strikePrices = Array.from({ length: 3001 }, (_, i) => i);

function AnnouncementForm() {
  // State for managing user inputs and form behavior
  const [message, setMessage] = useState(''); // For free-text messages
  const [files, setFiles] = useState([]); // Uploaded files
  const [filePreviews, setFilePreviews] = useState([]); // Previews of uploaded files
  const [platforms, setPlatforms] = useState({ telegram: false, discord: false, twitter: false }); // Platform selections
  const [BoughtSold, setBoughtSold] = useState('Bought'); // Bought/Sold dropdown selection
  const [callPut, setCallPut] = useState('Call'); // Call/Put dropdown selection
  const [ticker, setTicker] = useState(''); // Stock ticker input
  const [filteredSymbols, setFilteredSymbols] = useState(symbols); // Filtered symbols based on search
  const [strikePrice, setStrikePrice] = useState(''); // Strike price input
  const [filteredStrikePrices, setFilteredStrikePrices] = useState(strikePrices); // Filtered strike prices
  const [date, setDate] = useState(''); // Expiry date input
  const [price, setPrice] = useState(''); // Option price input
  const [SoldPositionSize, setSoldPositionSize] = useState('1'); // Sold position size for partial sells
  const [useDropdown, setUseDropdown] = useState(true); // Toggle between dropdown input and free-text input
  const [isLotto, setIsLotto] = useState(false); // Lotto checkbox state
  const fileInputRef = useRef(null); // Reference for file input field
  const navigate = useNavigate(); // Hook for navigating to other pages

  // Handle changes to the free-text message input field
  const handleInputChange = (e) => setMessage(e.target.value);

  // Auto-save logs when typing in the free-text message box
  useEffect(() => {
    if (!useDropdown && message.trim() !== '') {
      const autoSave = async () => {
        try {
          const logData = {
            message,
            timestamp: new Date().toISOString(),
          };
          await axios.post('https://rollin-trades-announcement-app.onrender.com/logs/normalLogs', { log: logData });
        } catch (error) {
          console.error('Error auto-saving log:', error);
        }
      };
      autoSave();
    }
  }, [message, useDropdown]);

  // Handle file uploads and generate preview URLs
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    setFilePreviews(selectedFiles.map((file) => URL.createObjectURL(file)));
  };

  // Handle platform checkbox selection (Telegram, Discord, Twitter)
  const handlePlatformChange = (e) => {
    const { name, checked } = e.target;
    setPlatforms((prevPlatforms) => ({ ...prevPlatforms, [name]: checked }));
  };

  // Append emoji to the free-text message
  const handleEmojiSelect = (emoji) => setMessage((prevMessage) => `${prevMessage}${emoji.native}`);

  // Filter stock tickers based on user input
  const handleTickerSearch = (e) => {
    const value = e.target.value.toUpperCase();
    setTicker(value);
    setFilteredSymbols(symbols.filter((symbol) => symbol.includes(value)));
  };

  // Filter strike prices based on user input
  const handleStrikePriceSearch = (e) => {
    const value = e.target.value;
    setStrikePrice(value);
    setFilteredStrikePrices(strikePrices.filter((price) => price.toString().startsWith(value)));
  };

  // Handle changes to the Lotto checkbox
  const handleLottoChange = (e) => setIsLotto(e.target.checked);

  // Function to send a message to Telegram
  const postToTelegram = async (message, chatId) => {
    const telegramToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
    const formData = new FormData();
    formData.append('chat_id', chatId);
    if (files.length > 0) {
      files.forEach((file, index) => formData.append(`photo${index}`, file));
      formData.append('caption', message);
    } else {
      formData.append('text', message);
    }
    const url = files.length > 0
      ? `https://api.telegram.org/bot${telegramToken}/sendMediaGroup`
      : `https://api.telegram.org/bot${telegramToken}/sendMessage`;
    try {
      await axios.post(url, formData);
    } catch (error) {
      console.error('Error posting to Telegram:', error);
    }
  };

  // Function to send a message to Discord
  const postToDiscord = async (message, webhookUrl) => {
    const formData = new FormData();
    formData.append('content', message);
    if (files.length > 0) {
      files.forEach((file, index) => formData.append(`file${index}`, file));
    }
    try {
      await axios.post(webhookUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    } catch (error) {
      console.error('Error posting to Discord:', error);
    }
  };

  // Function to send a message to Twitter
  const postToTwitter = async (message) => {
    const twitterFooter = "\n\n📩 Join our Discord for updates on long swings, live alerts, and more.\n👉 http://rollintrades.com";
    const twitterMessage = `${message}${twitterFooter}`;
    try {
      await axios.post('https://rollin-trades-announcement-app.onrender.com/twitter/post', { message: twitterMessage });
    } catch (error) {
      console.error('Error posting to Twitter:', error);
    }
  };

  // Handle form submission to send messages and save logs
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Format the message based on dropdown or free-text input
    const formattedMessage = useDropdown
      ? `${BoughtSold} ${
          BoughtSold === 'Sold'
            ? SoldPositionSize === '1'
              ? 'Full Position'
              : `${SoldPositionSize} of`
            : ''
        } $${ticker} ${strikePrice} ${callPut} $${price} ${date} ${
          isLotto ? 'Lotto' : ''
        }`
      : message;

    // Post message to selected platforms
    if (platforms.telegram) await postToTelegram(formattedMessage, process.env.REACT_APP_TELEGRAM_CHAT_ID);
    if (platforms.discord) {
      const discordWebhookUrl = useDropdown
        ? process.env.REACT_APP_DISCORD_WEBHOOK_URL_TRADE_ALERTS
        : process.env.REACT_APP_DISCORD_WEBHOOK_URL_PRO_ALERTS;
      await postToDiscord(formattedMessage, discordWebhookUrl);
    }
    if (platforms.twitter) await postToTwitter(formattedMessage);

    // Save log in the backend
    try {
      const logType = useDropdown ? 'dropdownLogs' : 'normalLogs';
      const logData = useDropdown
        ? {
            action: BoughtSold,
            ticker: ticker,
            strikePrice: strikePrice,
            optionType: callPut,
            price: price,
            expiryDate: date,
            isLotto: isLotto, // Include Lotto in log data
            SoldTransactions:
              BoughtSold === 'Sold'
                ? [
                    {
                      SoldPositionSize: SoldPositionSize,
                      SoldPrice: price,
                      SoldTimestamp: new Date().toISOString(),
                    },
                  ]
                : [],
            timestamp: new Date().toISOString(),
          }
        : {
            message: formattedMessage,
            isLotto: isLotto, // Include Lotto in free-text log data
            timestamp: new Date().toISOString(),
          };

      await axios.post(`https://rollin-trades-announcement-app.onrender.com/logs/${logType}`, { log: logData });
    } catch (error) {
      console.error('Error saving log:', error);
    }

    // Reset form fields
    setMessage('');
    setFiles([]);
    setFilePreviews([]);
    setBoughtSold('Bought');
    setCallPut('Call');
    setTicker('');
    setStrikePrice('');
    setDate('');
    setPrice('');
    setSoldPositionSize('1');
    setIsLotto(false); // Reset Lotto checkbox
    setFilteredSymbols(symbols);
    setFilteredStrikePrices(strikePrices);
  };

  // Clear selected image previews
  const handleClearImages = () => {
    setFiles([]);
    setFilePreviews([]);
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  // Navigate to logs view
  const handleViewLogs = () => navigate('/logs');

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/login');
  };

  return (
    <form className="announcement-form" onSubmit={handleSubmit}>
      <h1>Rollin Trades</h1>
      <h2>Send announcements to Discord, Telegram, and Twitter</h2>
      <label>
        Use Dropdown:
        <input
          type="checkbox"
          checked={useDropdown}
          onChange={(e) => setUseDropdown(e.target.checked)}
        />
      </label>
      {useDropdown ? (
        <div className="input-group">
          <label>Bought/Sold:</label>
          <select value={BoughtSold} onChange={(e) => setBoughtSold(e.target.value)}>
            <option value="Bought">Bought</option>
            <option value="Sold">Sold</option>
          </select>
          {BoughtSold === 'Sold' && (
            <>
              <label>Sold Position Size:</label>
              <select value={SoldPositionSize} onChange={(e) => setSoldPositionSize(e.target.value)}>
                <option value="1">Full Position</option>
                <option value="1/2">1/2</option>
                <option value="1/3">1/3</option>
                <option value="1/4">1/4</option>
              </select>
            </>
          )}

          <label>Call/Put:</label>
          <select value={callPut} onChange={(e) => setCallPut(e.target.value)}>
            <option value="Call">Call</option>
            <option value="Put">Put</option>
          </select>
          <label>Ticker:</label>
          <input type="text" value={ticker} onChange={handleTickerSearch} list="ticker-list" />
          <datalist id="ticker-list">
            {filteredSymbols.map((symbol) => (
              <option key={symbol} value={symbol}>
                {symbol}
              </option>
            ))}
          </datalist>
          <label>Strike Price:</label>
          <input type="number" value={strikePrice} onChange={handleStrikePriceSearch} />
          <label>Expiry Date:</label>
          <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
          <label>Price:</label>
          <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
          <label>
            Lotto:
            <input
              type="checkbox"
              checked={isLotto}
              onChange={handleLottoChange}
            />
          </label>
        </div>
      ) : (
        <div>
          <textarea value={message} onChange={handleInputChange} placeholder="Enter your message" />
          <div className="emoji-picker">
            <Picker data={data} onEmojiSelect={handleEmojiSelect} />
          </div>
        </div>
      )}
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        ref={fileInputRef}
        accept="image/*"
      />
      {filePreviews.length > 0 && (
        <div className="image-preview">
          {filePreviews.map((preview, index) => (
            <img key={index} src={preview} alt={`Preview ${index}`} />
          ))}
          <button type="button" onClick={handleClearImages}>
            Clear Images
          </button>
        </div>
      )}
      <div className="platform-selection">
        <label>
          <input
            type="checkbox"
            name="telegram"
            checked={platforms.telegram}
            onChange={handlePlatformChange}
          />
          Telegram
        </label>
        <label>
          <input
            type="checkbox"
            name="discord"
            checked={platforms.discord}
            onChange={handlePlatformChange}
          />
          Discord
        </label>
        <label>
          <input
            type="checkbox"
            name="twitter"
            checked={platforms.twitter}
            onChange={handlePlatformChange}
          />
          Twitter
        </label>
      </div>
      <button type="submit">Send</button>
      <button type="button" onClick={handleViewLogs}>
        View Logs
      </button>
      <button type="button" onClick={handleLogout}>
        Logout
      </button>
    </form>
  );
}

export default AnnouncementForm;
